/**
 * converts from mapbox gl bounds to lsd/api2 request params format
 *
 * @param {Array} bounds array as returned by mapbox gl
 * @returns {object} minLat, minLng, maxLat, maxLng
 */
export const convertBoundsToRequestParams = (bounds) => {
  if (bounds) {
    return {
      minLat: bounds[1][1],
      minLng: bounds[1][0],
      maxLat: bounds[0][1],
      maxLng: bounds[0][0],
    }
  } else {
    return {}
  }
}

/**
 * converts from turf bbox to
 *
 * @param {Array} bbox array as returned by turf bbox https://turfjs.org/docs/#bbox
 * @returns {object} ne, sw, as required by mapbox gl camera bounds https://github.com/react-native-mapbox-gl/maps/blob/master/docs/Camera.md
 */
export const convertBboxToBounds = (bbox) => {
  if (bbox) {
    return {
      ne: [bbox[0], bbox[3]],
      sw: [bbox[2], bbox[1]],
    }
  } else {
    return {}
  }
}
