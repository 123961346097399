import React, { useState, useCallback, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import useDebounce from 'react-use/lib/useDebounce'

import useAutocomplete from 'lsd/useAutocomplete'
import { PROVIDER_MAPBOX, PROVIDER_TRIAS } from 'util/constants'
import {
  Box,
  ListItemText,
  ListItemButton,
  TextField,
  ListItemIcon,
  Autocomplete,
  Popper,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import { ReactComponent as PublicTransport } from 'assets/icons/publictransport.svg'

const StyledInput = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      border: 'none',
    },
  },
})

/**
 * Component for searching using autocompletion
 * @component
 */
const Search = ({
  onInputValueChange,
  onSelect,
  providers,
  textFieldProps,
  itemToString,
  autoFocus,
  coordinates,
  clearInput,
}) => {
  /** String with the search value entered by user */
  const [currentSearchString, setCurrentSearchString] = useState('')
  const [debouncedSearchString, setDebouncedSearchString] = useState(null)

  // debouncedSearchString is used for requests to save resources and traffic
  // this is taken directly from useDebounce example: https://github.com/streamich/react-use/blob/master/docs/useDebounce.md
  // XXX useDebounce also returns an `isReady` state and a cancel function. currently we don't have use for them
  useDebounce(() => {
    setDebouncedSearchString(currentSearchString)
  }, 200, [currentSearchString])

  useEffect(() => {
    clearInput && setCurrentSearchString('')
  }, [clearInput])

  const {
    getAutocompleteRequest,
    // response object with value (an array of geojson features), loading and error state
    getAutocompleteResponse,
    // setter for request params to be used in addition to search string
    setCommonRequestParams,
  } = useAutocomplete()

  // sets request params that don't change with every search
  useEffect(() => {
    setCommonRequestParams({
      lng: coordinates[0],
      lat: coordinates[1],
      providers,
    })
  }, [providers, setCommonRequestParams, coordinates])

  // trigger search request when debouncedSearchString changes
  useEffect(() => {
    // don't request until at least 3 characters are typed
    if (debouncedSearchString?.length >= 3) {
      getAutocompleteRequest(debouncedSearchString)
    }
  }, [debouncedSearchString, getAutocompleteRequest])

  const results = useMemo(() => {
    return getAutocompleteResponse?.value ?? []
  }, [getAutocompleteResponse?.value])

  /**
   * Handles Input value change
   */
  const handleInputValueChange = useCallback(value => {
    setCurrentSearchString(value)
    onInputValueChange?.(value)
  }, [onInputValueChange])

  /**
   * Handles item selection and add choosen element in search array
   */
  const handleSelect = useCallback(item => {
    onSelect?.(item)
  }, [onSelect])

  /**
   * String representation of item
   */
  const itemToStringFn = useCallback(item => {
    return itemToString?.(item) ||
      item?.properties?.name ||
      item?.properties?.lines?.line_1 ||
      item?.properties?.id ||
      ''
  }, [itemToString])

  const generateListItem = (props, item, state) => {
    return (
            <ListItemButton
            {...props}
            key={item?.properties?.id}
            divider
            sx={{
              height: '56px',
            }}
            >
          <ListItemIcon>
            <PublicTransport />
          </ListItemIcon>
          <ListItemText
              primaryTypographyProps={{
                sx: {
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                },
              }}
              secondaryTypographyProps={{
                sx: {
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                },
              }}
            primary={item.properties?.lines?.line_1 ?? item.properties?.name}
            secondary={item.properties?.lines?.line_2}
              sx={{
                whiteSpace: 'nowrap',
              }}
          />
            </ListItemButton>
    )
  }

  return (
      <Box flex='1 1 auto' pb={1} pt={1}>
        <Autocomplete
            freeSolo
            id='search-autocomplete'
            filterOptions={(x) => x}
            renderInput={(params) =>
              <StyledInput
                  autoFocus={autoFocus}
                  {...textFieldProps}
                  {...params}
                  size='small'
                  InputProps={{
                    ...params?.InputProps,
                    ...textFieldProps?.InputProps,
                  }}
              />
            }
            PopperComponent={props => (
                // eslint-disable-next-line react/prop-types
                <Popper {...props} sx={{ ...props.style, paddingTop: theme => theme.spacing(0.5) }} />
            )}
            options={[...results]}
            renderOption={(params, item, state) => (
              generateListItem(params, item, state)
            )}
            getOptionLabel={itemToStringFn}
            onInputChange={(e, v) => handleInputValueChange(v)}
            onChange={(e, v) => handleSelect(v)}
            inputValue={currentSearchString}
        />
      </Box>
  )
}

Search.propTypes = {
  /** Callback when item is selected */
  onSelect: PropTypes.func,
  /** Props passed to the TextField input */
  textFieldProps: PropTypes.object,
  /** Item to string functions, used for the search result representation. */
  itemToString: PropTypes.object,
  /**
       * customize the providers for this search
       */
  providers: PropTypes.arrayOf(PropTypes.string),
  /** callback to access input value from outside */
  onInputValueChange: PropTypes.func,
  /** autofocus for input */
  autoFocus: PropTypes.bool,
  /** coordinates for autocompleter request */
  coordinates: PropTypes.array,
  /** if true the input is cleared */
  clearInput: PropTypes.bool,
}

Search.defaultProps = {
  providers: [PROVIDER_TRIAS, PROVIDER_MAPBOX],
  textFieldProps: {},
  autoFocus: true,
}

export default React.memo(Search)
