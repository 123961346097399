import React, { useState, createContext, useEffect, useCallback, useReducer } from 'react'
import PropTypes from 'prop-types'
import Swagger from 'swagger-client'

export const LsdContext = createContext({})
LsdContext.displayName = 'LsdContext'

/**
 * initializes lsd client, keeps global lsd state, provides context for lsd hooks
 *
 * @component
 */
const LsdProvider = ({
  children,
  swaggerUrl,
  username,
  password,
}) => {
  const [lsd, setLsd] = useState(null)
  const [ready, setReady] = useState(false)

  // generate lsd client once
  useEffect(() => {
    Swagger({
      url: swaggerUrl,
      authorizations: {
        basicAuth: {
          username,
          password,
        },
      },
    })
      .then(client => {
        setLsd(client.apis)
        setReady(true)
        return client.apis
      })
      .catch(err => {
        // eslint-disable-next-line no-console
        console.error('Error while creating lsdClient based on swaggerfile', err)
      })
  }, [password, swaggerUrl, username])

  // because single connections cannot be retrieved from backend,
  // a cache is provided where connections can be stored and retrieved by id
  // currently this cache is never purged but also not persisted when app is closed
  const [connectionCache, dispatchConnectionCache] = useReducer((state, action) => {
    // eslint-disable-next-line default-case
    switch (action.type) {
      case 'store':
        return {
          ...state,
          [action.id]: action.payload,
        }
    }
  }, {})

  /**
   * adds connection to the cache by connectionId
   */
  const storeConnectionInCache = useCallback((connection) => {
    dispatchConnectionCache({ type: 'store', id: connection?.properties?.connectionId, payload: connection })
  }, [])

  /**
   * looks up connection in cache by connectionId
   */
  const retrieveConnectionFromCache = useCallback((id) => {
    return connectionCache?.[id]
  }, [connectionCache])

  return ready && (
    <LsdContext.Provider value={{
      lsd,
      storeConnectionInCache,
      retrieveConnectionFromCache,
    }}
    >
      {children}
    </LsdContext.Provider>
  )
}

LsdProvider.propTypes = {
  children: PropTypes.node,
  /**
   * url pointing to swagger specification
   */
  swaggerUrl: PropTypes.string.isRequired,
  /**
   * username for lsd requests
   */
  username: PropTypes.string.isRequired,
  /**
   * password for lsd requests
   */
  password: PropTypes.string.isRequired,
}

export default LsdProvider
