import { useContext, useState } from 'react'
import { LsdContext } from './LsdProvider'
import useAsyncFn from 'react-use/lib/useAsyncFn'

import {
  PLATFORM_REGIO,
  LANGUAGE_GERMAN,
} from 'util/constants.js'

/**
 * @typedef {object} AsyncFnState
 * @property {object} value value if completed
 * @property {boolean} loading true if currently loading
 * @property {object} error error if failed
 */

/**
 * @typedef {object} ReturnObject
 * @property {Function} getAutocompleteRequest callback to execute getAutocomplete request
 * @property {AsyncFnState} getAutocompleteResponse value, loading and error for getAutocomplete
 * @property {Function} setCommonRequestParams setter for request params to be used in every request
 */

/**
 * Hook to load stations from the autocompleter by using the search string
 *
 * @returns {ReturnObject} request, response and setter for request params for useAutocomplete
 */
const useAutocomplete = () => {
  const { lsd } = useContext(LsdContext)

  const [commonRequestParams, setCommonRequestParams] = useState({})

  /**
   * Returns the result for the search string from the API
   */
  const [getAutocompleteResponse, getAutocompleteRequest] = useAsyncFn(async (searchString) => {
    if (searchString) {
      const response = await lsd.Poi.getAutocomplete({
        ...commonRequestParams,
        charSeq: searchString,
        platform: PLATFORM_REGIO,
        lang: LANGUAGE_GERMAN,
      })
      return response.body.result?.features
    }
  }, [commonRequestParams])

  return {
    getAutocompleteResponse,
    getAutocompleteRequest,
    setCommonRequestParams,
  }
}

export default useAutocomplete
