import * as React from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'

// Panel UI Component
const TabPanel = props => {
  const { children, value, index, ...other } = props

  return (
        <Box
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            sx={{
              height: (theme) => `calc(100% - ${theme.spacing(6)})`,
            }}
            {...other}
        >
            {value === index && (
                <Box
                    sx={{
                      p: 3,
                      pb: 0,
                      pt: 1,
                      height: (theme) => `calc(100% - ${theme.spacing()})`,
                      display: 'flex',
                    }}
                >
                    {children}
                </Box>
            )}
        </Box>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
}

export default TabPanel
