export const DEFAULT_LAT_KARLSRUHE = 49.0136
export const DEFAULT_LNG_KARLSRUHE = 8.4044
export const DEFAULT_ZOOM = 12

/** Autocompleter */
export const PLATFORM_REGIO = 'regio'
export const PROVIDER_MAPBOX = 'mapbox'
export const PROVIDER_TRIAS = 'trias'
export const PROVIDER_NEXTBIKE = 'nextbike'
export const PROVIDER_STADTMOBIL = 'stadtmobil'
export const LANGUAGE_GERMAN = 'de'
export const SOURCE_MAPBOX = 'mapbox'

// MobilityProvider state
export const MOBILITY_PROVIDER_STATE_ACTIVE = 'Active'

// booking
// booking item states as defined by INIT
export const BOOKING_TYPE_ACTIVE = 'Active'
export const BOOKING_TYPE_INACTIVE = 'Inactive'
export const BOOKING_TYPE_CANCELED = 'Canceled'
export const BOOKING_TYPE_CLEARED = 'Cleared'
export const BOOKING_TYPE_FAILED = 'Failed'

// Booking Item rentalStates as defined By INIT
export const BOOKING_RENTALSTATE_FINISHED = 'Finished'
export const BOOKING_RENTALSTATE_INACTIVE = 'Inactive'
export const BOOKING_RENTALSTATE_ACTIVE = 'Active'
export const BOOKING_RENTALSTATE_PAUSED = 'Paused'
export const RENTAL_STATE_FINISHED = 'Finished'

export const NEXTBIKE_BOOKINGTYPE_RESERVED = 'Reserved'
export const KVV_REFUND = 'Refund'

/** Personal Data */
// gender is derived from salutation
export const SALUTATION_TO_GENDER_TABLE = {
  MR: 'MALE',
  MRS: 'FEMALE',
  UNKNOWN: 'NONBINARY',
}

/** Mobility provider */
export const MOBILITY_PROVIDER_KVV = 'KVV'
export const MOBILITY_PROVIDER_NUMBER_KVV = 1
export const MOBILITY_PROVIDER_NEXTBIKE = 'NextBike'
export const MOBILITY_PROVIDER_NUMBER_NEXTBIKE = 2
export const MOBILITY_PROVIDER_STADTMOBIL = 'StadtMobil'
export const MOBILITY_PROVIDER_NUMBER_STADTMOBIL = 3
export const HOMEZONE = 'homezone'

/** ConnectionsearchProvider Keys */
export const CONNECTIONSEARCH_PROVIDER_PUBLICTRANSPORT = 'publictransport'
export const CONNECTIONSEARCH_PROVIDER_STADTMOBIL = 'stadtmobil'
export const CONNECTIONSEARCH_PROVIDER_NEXTBIKE = 'nextbike'
export const CONNECTIONSEARCH_PROVIDER_CAR = 'car'
export const CONNECTIONSEARCH_PROVIDER_BIKE = 'bike'
export const CONNECTIONSEARCH_PROVIDER_FOOT = 'foot'

// Returns identifier for railcard options. Will be served by API/LSD someday.
// First entry in returned array corresponds to 'no railway card'.
// Values inside array are used as i18next identifiers also.
export const BAHNCARD_OPTION_NO_BAHNCARD = '0'
export const BAHNCARD_OPTIONS = [BAHNCARD_OPTION_NO_BAHNCARD, '252', '502', '102', '251', '501', '101']

// constants for day tickets
export const VALIDITY_AREA_CITY = 'CITY'
export const VALIDITY_AREA_REGIO = 'REGIO'
export const VALIDITY_AREA_REGIO_SPECIAL = 'REGIO_SPECIAL'

// constants for add-on tickets
export const TICKET_TYPE_ADD_ON_ADULT = '1001'
export const TICKET_TYPE_ADD_ON_CHILD = '2001'

/* infoPriceConnection */
export const OPERATOR_KVV = 'kvv'

/* canceled trains */
export const TRIP_CANCELLED = 'TRIP_CANCELLED'
export const DEPARTURE_CANCELLED = 'DEPARTURE_CANCELLED'

// Stadtmobil booking constants
export const STADTMOBIL_CANCELSTATE_FREE = 'freeOfCharge'
export const STADTMOBIL_CANCELSTATE_FULLTIMEFEE = 'chargeFullOfTimeFee'
export const STADTMOBIL_CANCELSTATE_HALFTIMEFEE = 'chargeHalfOfTimeFee'

// constants for favourites
export const FAVOURITE_STATION = 'TRIAS'
export const FAVOURITE_NEXTBIKE = 'NEXTBIKE'
export const FAVOURITE_STADTMOBIL = 'STADTMOBIL'
export const FAVOURITE_CITY = 'city'
export const FAVOURITE_ADDRESS = 'address'
