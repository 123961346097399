import React, {
  forwardRef,
  useMemo,
} from 'react'
import PropTypes from 'prop-types'
import { PRIMARY_COLOR, HOMEZONE_COLOR } from 'util/styleConstants'
import { useWindowSize } from 'react-use'

/**
 * This is a dynamically generated SVG that represents the home zone while it is modified.
 * It consists of a circle and a price tag which are always visible and other elements
 * which are displayed depending on the mode:
 * - in 'centerAndRadius' mode a line is drawn from the center to the left boundary and a
 *   badge shows the length of the radius
 * - in 'boundaryPoints' mode two markers indicate the boundary points which define the size
 *
 * @see {@link https://github.com/react-native-svg/react-native-svg} docs for react-native-svg
 * @component
 */
const HomeZoneOverlay = forwardRef(({
  boundaryPointsAngle,
  mode,
  price,
  radius,
}, ref) => {
  // calculates the carthesian coordinates in a unit circle
  // of boundary point 2 (initially on the right) from the angle
  // see https://en.wikipedia.org/wiki/Unit_circle#/media/File:Unit_circle.svg
  const boundaryPoint2InUnitCircle = useMemo(() => {
    return {
      x: Math.cos(boundaryPointsAngle),
      y: Math.sin(boundaryPointsAngle),
      // these are two additional points for drawing a polygon that touches the circle with the number
      // XXX the angle modifiers are fine tuned by trial and error
      x2: Math.cos(boundaryPointsAngle + Math.PI / 55),
      y2: Math.sin(boundaryPointsAngle + Math.PI / 55),
      x3: Math.cos(boundaryPointsAngle - Math.PI / 55),
      y3: Math.sin(boundaryPointsAngle - Math.PI / 55),
    }
  }, [boundaryPointsAngle])

  // same for boundary point 1, these are point reflections of boundary point 2
  const boundaryPoint1InUnitCircle = useMemo(() => {
    return {
      x: -1 * boundaryPoint2InUnitCircle.x,
      y: -1 * boundaryPoint2InUnitCircle.y,
      x2: -1 * boundaryPoint2InUnitCircle.x2,
      y2: -1 * boundaryPoint2InUnitCircle.y2,
      x3: -1 * boundaryPoint2InUnitCircle.x3,
      y3: -1 * boundaryPoint2InUnitCircle.y3,
    }
  }, [boundaryPoint2InUnitCircle])

  // scales and translates the unit circle coordinates to the actual svg.
  const boundaryPoint2InOverlayCircle = useMemo(() => {
    return {
      // circle with number is placed on a smaller radius (45 instead of 50)
      // because everything exceeding the 100x100 svg viewbox will be cut off.
      circle: {
        x: boundaryPoint2InUnitCircle.x * 45 + 50,
        y: boundaryPoint2InUnitCircle.y * 45 + 50,
      },
      // polygon consists of tip on the radius circle and two points on the circle with the number
      // XXX the latter coordinates are fine tuned by trial and error
      polygon: {
        points: `
          ${boundaryPoint2InUnitCircle.x * 50 + 50},${boundaryPoint2InUnitCircle.y * 50 + 50}
          ${(boundaryPoint2InUnitCircle.x2 * 46.5) + 50},${(boundaryPoint2InUnitCircle.y2 * 46.5) + 50}
          ${(boundaryPoint2InUnitCircle.x3 * 46.5) + 50},${(boundaryPoint2InUnitCircle.y3 * 46.5) + 50}
        `,
      },
    }
  }, [boundaryPoint2InUnitCircle.x, boundaryPoint2InUnitCircle.x2, boundaryPoint2InUnitCircle.x3, boundaryPoint2InUnitCircle.y, boundaryPoint2InUnitCircle.y2, boundaryPoint2InUnitCircle.y3])

  // same for boundary point 1
  const boundaryPoint1InOverlayCircle = useMemo(() => {
    return {
      // circle with number is placed on a smaller radius (45 instead of 50)
      // because everything exceeding the 100x100 svg viewbox will be cut off.
      circle: {
        x: boundaryPoint1InUnitCircle.x * 45 + 50,
        y: boundaryPoint1InUnitCircle.y * 45 + 50,
      },
      // polygon consists of tip on the radius circle and two points on the circle with the number
      // XXX the latter coordinates are fine tuned by trial and error
      polygon: {
        points: `
          ${boundaryPoint1InUnitCircle.x * 50 + 50},${boundaryPoint1InUnitCircle.y * 50 + 50}
          ${(boundaryPoint1InUnitCircle.x2 * 46.5) + 50},${(boundaryPoint1InUnitCircle.y2 * 46.5) + 50}
          ${(boundaryPoint1InUnitCircle.x3 * 46.5) + 50},${(boundaryPoint1InUnitCircle.y3 * 46.5) + 50}
        `,
      },
    }
  }, [boundaryPoint1InUnitCircle.x, boundaryPoint1InUnitCircle.x2, boundaryPoint1InUnitCircle.x3, boundaryPoint1InUnitCircle.y, boundaryPoint1InUnitCircle.y2, boundaryPoint1InUnitCircle.y3])

  // determine view mode based on innerWidth/-height
  const { width, height } = useWindowSize()
  const isLandscape = useMemo(() => {
    return width > height
  }, [width, height])

  return (
      <svg
        ref={ref}
        viewBox='0 0 100 100'
        aspectratio='1'
        style={
        !isLandscape
          ? {
              maxHeight: '100%',
              maxWidth: '100%',
              width: '100%',
              height: 'auto',
            }
          : {
              maxHeight: '100%',
              maxWidth: '100%',
              width: 'auto',
              height: '100%',
            }
      }
      >
        {/* circle (double stroke width but clipped outside the circle)
          https://alexwlchan.net/2021/03/inner-outer-strokes-svg/ */}
        <defs>
          <clipPath id='circle'>
            <circle r={50} cx={50} cy={50} />
          </clipPath>
        </defs>
        <circle r={50} cx={50} cy={50} stroke={PRIMARY_COLOR} fill='none' strokeWidth={1} clipPath='url(#circle)' />
        {/* radius and label */}
        { mode === 'centerAndRadius' && (
          <>
            <line x1={50} y1={50} x2={0} y2={50} stroke={HOMEZONE_COLOR} strokeWidth={1} />
            <circle r={1} cx={50} cy={50} fill={HOMEZONE_COLOR} stroke={HOMEZONE_COLOR} strokeWidth={1} />
            <rect x={15} y={47} rx={1} width={12} height={3} fill={HOMEZONE_COLOR} />
            <text x={22} y={49} textAnchor='middle' fill='white' fontSize={2} fontWeight={600}>
              {
                // XXX Intl on android currently does not support style unit
                // radius?.toLocaleString('de', { style: 'unit', unit: 'kilometer', maximumFractionDigits: 1 })
                radius ? radius?.toLocaleString('de', { style: 'decimal', maximumFractionDigits: 1 }) + ' km' : '-'
              }
            </text>
          </>
        )}
        { mode === 'boundaryPoints' && (
          <>
            {/* these are placed on a smaller circle (radius 45 instead of 50)
              because everything exceeding the 100x100 svg viewbox will be cut off.
              XXX the polygon coordinates (except for the tip) are fine tuned by trial and error */}
            <circle r={3} cx={boundaryPoint1InOverlayCircle.circle.x} cy={boundaryPoint1InOverlayCircle.circle.y} fill={HOMEZONE_COLOR} />
            <polygon points={boundaryPoint1InOverlayCircle.polygon.points} fill={HOMEZONE_COLOR} />
            <text x={boundaryPoint1InOverlayCircle.circle.x} y={boundaryPoint1InOverlayCircle.circle.y} dominantBaseline='middle' alignmentBaseline='middle' textAnchor='middle' fill='white' fontSize={3} fontWeight={600} >1</text>
            <circle r={3} cx={boundaryPoint2InOverlayCircle.circle.x} cy={boundaryPoint2InOverlayCircle.circle.y} fill={HOMEZONE_COLOR} />
            <polygon points={boundaryPoint2InOverlayCircle.polygon.points} fill={HOMEZONE_COLOR} />
            <text x={boundaryPoint2InOverlayCircle.circle.x} y={boundaryPoint2InOverlayCircle.circle.y} dominantBaseline='middle' alignmentBaseline='middle' textAnchor='middle' fill='white' fontSize={3} fontWeight={600} >2</text>
          </>
        )}
        {/* price tag */}
        <rect x={80} y={0} rx={3} width={20} height={6} fill={PRIMARY_COLOR} />
        <polygon points='81,2 84,5 77,8' fill={PRIMARY_COLOR} />
        <text x={90} y={4} textAnchor='middle' fill='white' fontSize={3} fontWeight={600}>
          {price ? price.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' }) : '-'}
        </text>
      </svg>
  )
})

HomeZoneOverlay.displayName = 'HomeZoneOverlay'

HomeZoneOverlay.propTypes = {
  /**
   * angle of a line through the boundary points in radians
   */
  boundaryPointsAngle: PropTypes.number,
  /**
   * switches between modes for center/radius and boundary points
   */
  mode: PropTypes.oneOf([
    'centerAndRadius',
    'boundaryPoints',
  ]),
  /**
   * price in Euro (will be formatted by component)
   */
  price: PropTypes.number,
  /**
   * radius in km (will be formatted by component)
   */
  radius: PropTypes.number,
}

HomeZoneOverlay.defaultProps = {
  mode: 'centerAndRadius',
}

export default React.memo(HomeZoneOverlay)
