import { useMemo, useState, useCallback, useEffect } from 'react'
import turfCircle from '@turf/circle'
import turfBbox from '@turf/bbox'

const INITIAL_RADIUS = 2
const STEP_SIZE = 0.001

/**
 * Hook for syncing circle to slider.
 * A change in radius will update the slider value.
 * When user changes slider, camera will automatically fit bounds to new radius.
 * Uses the 'prop-getter' pattern, a slider component needs to call 'getSliderProps' of the hook
 *
 * @component
 */
const useRadiusSlider = (minRadius, maxRadius, calcRadius, radius, radiusCenter, map) => {
  const [sliderValue, setSliderValue] = useState(Math.log(INITIAL_RADIUS))

  /**
   * Set slider value after slider movement
   * @type {(function(*, *): void)|*}
   */
  const handleChangeCommitted = useCallback((e, value) => {
    if (value !== sliderValue) {
      setSliderValue(value)
    }
  }, [setSliderValue, sliderValue])

  useEffect(() => {
    setSliderValue(Math.log(radius))
  }, [radius])

  // When slider is used, calculate new bbox for radius and
  // fit camera accordingly
  const handleValueChange = useCallback((e, value) => {
    setSliderValue(value)
    if (radiusCenter && map?.current) {
      const circle = turfCircle(radiusCenter, Math.exp(value)) // logarithmic scale
      const bbox = turfBbox(circle)
      map?.current?.fitBounds(
        [
          [bbox[0], bbox[3]],
          [bbox[2], bbox[1]],
        ],
        {
          linear: true,
        }
      )
    }
  }, [map, radiusCenter])

  const getSliderProps = useMemo(() => {
    return {
      max: Math.log(maxRadius), // logarithmic scale
      min: Math.log(minRadius),
      step: STEP_SIZE,
      onChange: handleValueChange,
      onChangeCommitted: handleChangeCommitted,
      value: sliderValue,
      valueLabelDisplay: 'auto',
      valueLabelFormat: v => `${Math.exp(v).toFixed(2)} km`,
    }
  }, [handleValueChange, sliderValue, minRadius, maxRadius, handleChangeCommitted])

  return {
    getSliderProps,
  }
}

export default useRadiusSlider
