import {
  useContext,
  useEffect,
} from 'react'
import useAsyncFn from 'react-use/lib/useAsyncFn'
import { SmuContext } from 'util/SmuProvider'

/**
 * @typedef {object} AsyncFnState
 * @property {object} value value if completed
 * @property {boolean} loading true if currently loading
 * @property {object} error error if failed
 */

/**
 * @typedef {object} Queries
 * @property {AsyncFnState} definition value, loading and error for home zone definition
 * @property {AsyncFnState} stops value, loading and error for home zone stops
 */

/**
 * a hook to query data while editing a home zone.
 * it performs separate requests for home zone definiton and stops.
 *
 * @param {number} lat latitude of center
 * @param {number} lng longitude of center
 * @param {number} radius radius in meters (decimal places will be processed by `toFixed()`)
 * @returns {Queries} object containing definition and stops, each with value, loading and error
 */
const useHomeZoneQueries = (lat, lng, radius) => {
  const { smu } = useContext(SmuContext)

  // first request: getDefinition
  const [definition, getDefinition] = useAsyncFn(async () => {
    if (lat && lng && radius) {
      const response = await smu?.apis?.['home-zone-controller']?.getHomeZoneDefinition({
        lat,
        lng,
        // radius must not have decimal places
        radius: radius?.toFixed(),
      })
      return response?.body
    }
  }, [lat, lng, radius])

  // second request: getStops
  const [stops, getStops] = useAsyncFn(async () => {
    if (lat && lng && radius) {
      const response = await smu?.apis?.['home-zone-controller']?.getHomeZoneStops({
        lat,
        lng,
        // radius must not have decimal places
        radius: radius?.toFixed(),
      })
      return response?.body
    }
  }, [lat, lng, radius])

  // trigger both requests on change
  useEffect(() => {
    getDefinition()
    getStops()
  }, [getDefinition, getStops])

  // log errors (will be displayed in LogBox in development mode)
  useEffect(() => {
    if (definition?.error) {
      // eslint-disable-next-line no-console
      console.error(definition?.error)
    }
    if (stops?.error) {
      // eslint-disable-next-line no-console
      console.error(definition?.error)
    }
  }, [definition?.error, stops?.error])

  return {
    definition,
    stops,
  }
}

export default useHomeZoneQueries
