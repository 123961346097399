import { forwardRef, useEffect, useMemo } from 'react'
import turfBearing from '@turf/bearing'
import * as turfHelpers from '@turf/helpers'
import turfCenter from '@turf/center'
import turfCircle from '@turf/circle'
import turfBbox from '@turf/bbox'
import turfDistance from '@turf/distance'
import { Box } from '@mui/material'
import HomeZoneOverlay from 'components/homeZone/HomeZoneOverlay'
import * as React from 'react'
import PropTypes from 'prop-types'
import PoiLayer from './pois/PoiLayer'

// Overlay Wrapper for Homezone Circle, mainly used for Positioning and some calculation
const MapOverlay = forwardRef(({
  setViewport,
  boundaryPoint1,
  boundaryPoint2,
  overlayMode,
  definition,
  radius,
  map,
}, ref) => {
  // Calculate the angle of the boundary Points
  const boundaryPointsAngle = useMemo(() => {
    if (boundaryPoint1 && boundaryPoint2) {
      // bearing is deviation from north line
      const bearing = turfBearing(boundaryPoint1, boundaryPoint2)
      // in a unit circle degrees are measured from the x axis, counter clockwise and in radians instead of degrees
      return -1 * turfHelpers.degreesToRadians(90 - bearing)
    } else {
      return 0
    }
  }, [boundaryPoint1, boundaryPoint2])

  // Price extraction from definition
  const price = useMemo(() => {
    return definition?.value?.price ? (definition.value.price / 100) : null
  }, [definition.value])

  /**
     * this callback determines the circle of the home zone defined by two boundary points
     * and moves the camera accordingly
     */
  useEffect(() => {
    if (boundaryPoint1 && boundaryPoint2 && map?.current) {
      // center of straight line between the two boundary points
      const newCenter = turfCenter(turfHelpers.points([boundaryPoint1, boundaryPoint2]))
      // radius is half of the length of that line
      const newRadius = (turfDistance(boundaryPoint1, boundaryPoint2) / 2) * 1.02 // buffer of 2 percent to make sure points are included
      // create a geojson circle to use with fitBounds
      const newCircle = turfCircle(newCenter, newRadius)
      const newCircleBbox = turfBbox(newCircle)
      map.current.fitBounds([[newCircleBbox[0], newCircleBbox[3]], [newCircleBbox[2], newCircleBbox[1]]])
    }
  }, [boundaryPoint1, boundaryPoint2, map])

  return (
        <>
            <Box
                sx={{
                  display: 'flex',
                  margin: (theme) => theme.spacing(6, 8, 6, 0),
                  height: (theme) => `calc(100% - ${theme.spacing(12)})`,
                  marginLeft: '40%',
                  textAlign: 'right',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                  pointerEvents: 'none',
                  width: 'auto',
                }}
            >
                <Box sx={{ height: '100%', flex: '1 1 auto', zIndex: 1, display: 'flex', alignItems: 'center' }}>
                    <HomeZoneOverlay
                        ref={ref}
                        boundaryPointsAngle={boundaryPointsAngle}
                        mode={overlayMode}
                        price={price}
                        radius={radius}
                    />
                </Box>
            </Box>
        </>
  )
})

MapOverlay.propTypes = {
  /** Set viewport of Map */
  setViewport: PropTypes.func,
  /** Coordinates of bP1 */
  boundaryPoint1: PropTypes.array,
  /** Coordinates of bP2 */
  boundaryPoint2: PropTypes.array,
  /** Active Mode (BoundaryPoints or Radius) */
  overlayMode: PropTypes.string,
  /** Radius of the Homezone */
  radius: PropTypes.number,
  /** Definition of the homezone */
  definition: PropTypes.object,
  /** Map ref */
  map: PropTypes.object,
}

PoiLayer.defaultProps = {
  overlayMode: 'centerAndRadius',
}

export default React.memo(MapOverlay)
