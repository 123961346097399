import * as geohash from 'ngeohash'
import { useCallback, useContext, useEffect, useState, useMemo } from 'react'
import { featureCollection } from '@turf/helpers'
import memoize from 'memoizee'

import { LsdContext } from './LsdProvider'
import { LANGUAGE_GERMAN, PLATFORM_REGIO, PROVIDER_TRIAS } from 'util/constants'

const GEOHASH_PRECISION = 4

/**
 * Fetches feature data based on map bounds.
 * Uses getGrid request so calls get cached
 *
 * @param {number} minLat -  minLat
 * @param {number} minLng -  minLng
 * @param {number} maxLat -  maxLat
 * @param {number} maxLng -  maxLng
 * @param {string} platform - platform
 * @param {string} source -  source
 * @param {string} type -  type
 * @param {string} lang -  lang
 * @param {string} providerParams - providerParams object strigified
 * @returns {object} geojson feature
 */
const useGrid = (
  minLat,
  minLng,
  maxLat,
  maxLng,
  platform = PLATFORM_REGIO,
  source = PROVIDER_TRIAS,
  type = 'station',
  lang = LANGUAGE_GERMAN,
  providerParams
) => {
  const { lsd } = useContext(LsdContext)
  const [pois, setPois] = useState(null)

  /**
   * Convert bbox to geohash array
   *
   * @param {object} bounds bounds of the map
   * @returns {Array} geohashes bounds converted to araay with geohashes
   */
  const geohashesFromBbox = useMemo(() => {
    const geohashes = geohash.bboxes(
      minLat,
      minLng,
      maxLat,
      maxLng,
      GEOHASH_PRECISION
    )
    return geohashes
  }, [maxLat, maxLng, minLat, minLng])

  /**
   * Memoized getGrid request of single geohash
   */
  const memoizedGetGrid = useMemo(() => {
    const getGrid = ({
      hash,
      ...otherParams
    }) => {
      if (!lsd?.Broker?.getGrid) {
        return Promise.reject(new Error('lsd not ready'))
      }
      return lsd.Broker.getGrid({
        hashes: [hash],
        ...otherParams,
      })
    }
    return memoize(getGrid, {
      normalizer: args => JSON.stringify(args),
    })
  }, [lsd])

  /**
   * Fetches data of a single geohash
   *
   * @param {string} geohash geohash
   * @returns {Array} features Features inside the geohash
   */
  const fetchSingleGeohashData = useCallback((geohash) => {
    return memoizedGetGrid({
      hash: geohash,
      platform,
      lang,
      source,
      type,
      providerParams,
    })
      .then(response => {
        return response?.obj?.result?.[geohash]?.features || null
      })
      .catch(e => {
        // eslint-disable-next-line no-console
        console.error(e)
        return null
      })
  }, [lang, memoizedGetGrid, platform, providerParams, source, type])

  /**
   * Fetches data of an geohash array
   *
   * @returns {Array} data FeatureCollection with all features inside the geohashes
   */
  const fetchGeohashData = useCallback(() => {
    const promises = geohashesFromBbox.map(fetchSingleGeohashData)

    return Promise.all(promises).then(values => {
      const features = values
        .filter(v => v !== null)
        .reduce((prev, current) => prev.concat(current), [])
      const fc = featureCollection(features)
      return fc
    })
  }, [fetchSingleGeohashData, geohashesFromBbox])

  // Fetch new pois if geo hashes have changed
  useEffect(() => {
    fetchGeohashData()
      .then(data => {
        setPois(data)
      })
  }, [fetchGeohashData, setPois])

  return pois && pois
}

export default useGrid
