import React, { createContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Swagger from 'swagger-client'

export const SmuContext = createContext({})
SmuContext.displayName = 'SmuContext'

/**
 * initializes smu client, keeps global smu state, provides context for smu hooks
 *
 * @component
 */
const SmuProvider = ({
  children,
  swaggerUrl,
}) => {
  const [smu, setSmu] = useState(null)

  // Init SMU
  useEffect(() => {
    Swagger({
      url: swaggerUrl,
    })
      .then(client => {
        setSmu(client)
        return client
      })
      .catch(err => {
        // eslint-disable-next-line no-console
        console.error('Error while creating SmuClient based on swaggerfile', err)
      })
  }, [swaggerUrl/*, userId */])

  return (
    <SmuContext.Provider
      value={{
        smu: smu,
      }}
    >
      {children}
    </SmuContext.Provider>
  )
}

SmuProvider.propTypes = {
  children: PropTypes.node,
  /**
   * url pointing to swagger specification
   */
  swaggerUrl: PropTypes.string.isRequired,
}

export default SmuProvider
