import React, { useMemo } from 'react'
import { Avatar, List, ListItem, ListItemAvatar, ListItemText } from '@mui/material'
import { ReactComponent as PublicTransport } from 'assets/icons/publictransport.svg'
import PropTypes from 'prop-types'

const providerStyle = {
  kvv: {
    primary: '#008754',
    secondary: '#f0c802',
  },
}

// Shows all the pois in the homezone
const MapHomeZoneDetailList = props => {
  const { items } = props

  // sort list alphabetically
  const sortedItems = useMemo(() => {
    if (items?.length > 0) {
      return [...items]?.sort((a, b) => {
        const fa = a.properties.name
        const fb = b.properties.name
        return fa.localeCompare(fb)
      })
    }
  }, [items])

  return (
        <List
            sx={{
              overflow: 'auto',
            }}
        >
            {sortedItems?.map(item => (
                <ListItem
                    key={item.properties?.id}
                    sx={{
                      height: '56px',
                    }}
                    divider
                >
                    <ListItemAvatar>
                        <Avatar
                            sx={{
                              bgcolor: providerStyle.kvv.secondary,
                            }}
                        >
                            <PublicTransport className={'publictransport'} />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primaryTypographyProps={{
                          sx: {
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                          },
                        }}
                        secondaryTypographyProps={{
                          sx: {
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                          },
                        }}
                        primary={item.properties?.name}
                        secondary={item?.properties?.address?.city}
                        sx={{
                          whiteSpace: 'nowrap',
                        }}
                    />
                </ListItem>
            ))}

        </List>
  )
}

MapHomeZoneDetailList.propTypes = {
  /** Array of items inside the homezone */
  items: PropTypes.array,
}

export default React.memo(MapHomeZoneDetailList)
