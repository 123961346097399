import React from 'react'
import './App.css'
import SmuProvider from './util/SmuProvider'
import LsdProvider from './lsd/LsdProvider'
import MapHomeZoneScreen from './components/MapHomeZoneScreen'
import AppStoreLinks from './components/AppStoreLinks'
import { useWindowSize } from 'react-use'

function App () {
  const { width } = useWindowSize()
  return (
    <div className="App">
        <SmuProvider swaggerUrl={process.env.REACT_APP_SMU_SERVER + process.env.REACT_APP_SMU_BASE_PATH + process.env.REACT_APP_SMU_SWAGGER_PATH}>
            <LsdProvider
                swaggerUrl={process.env.REACT_APP_LSD_SERVER + process.env.REACT_APP_LSD_BASE_PATH + process.env.REACT_APP_LSD_SWAGGER_PATH}
                username={process.env.REACT_APP_LSD_USER}
                password={process.env.REACT_APP_LSD_PASSWORD}
            >
              {width < 960 ? <AppStoreLinks /> : <MapHomeZoneScreen/>}
            </LsdProvider>
        </SmuProvider>
    </div>
  )
}

export default App
