import * as React from 'react'
import { Box, Tab, Tabs } from '@mui/material'
import { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import TabPanel from './TabPanel'

// Sidebar floating above Map
const FloatingSidebar = props => {
  const { changeFunc, RadiusPC, PointsPC } = props
  const [value, setValue] = useState(0)

  const handleChange = useCallback((e, nv) => {
    changeFunc && changeFunc(nv)
    setValue(nv)
  }, [changeFunc])

  const a11yProps = index => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    }
  }

  return (
        <Box
            sx={{
              minWidth: '200px',
              maxWidth: '30%',
              width: '360px',
              height: '90%',
              left: '5%',
              zIndex: 2,
              position: 'absolute',
              borderRadius: '5px',
              backgroundColor: '#FFFFFF',
              boxShadow: '0 2px 4px 0 rgba(0,0,0,0.5)',
              top: '5%',
            }}
        >
            <Box width='100%' height='48px'>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="secondary"
                    textColor='inherit'
                    TabIndicatorProps={{
                      sx: {
                        height: '2px',
                        backgroundColor: '#FD7888',
                      },
                    }}
                >
                    <Tab
                        label="Über Radius"
                        {...a11yProps(0)}
                        sx={{
                          backgroundColor: value === 0 ? '#596FBD' : '#12297A',
                          width: '50%',
                          color: 'white',
                          textTransform: 'none',
                          opacity: 1,
                        }}
                    />
                    <Tab
                        label="Über Randpunkte"
                        {...a11yProps(1)}
                        sx={{
                          backgroundColor: value === 1 ? '#596FBD' : '#12297A',
                          width: '50%',
                          color: 'white',
                          textTransform: 'none',
                          opacity: 1,
                        }}
                    />
                </Tabs>
            </Box>
            <TabPanel
                value={value}
                index={0}
            >
                {RadiusPC && RadiusPC}
            </TabPanel>
            <TabPanel
                value={value}
                index={1}
            >
                {PointsPC && PointsPC}
            </TabPanel>
        </Box>
  )
}

FloatingSidebar.propTypes = {
  /** Function for tab change */
  changeFunc: PropTypes.func,
  /** Radius mode panel content node */
  RadiusPC: PropTypes.node,
  /** Points mode panel content node */
  PointsPC: PropTypes.node,
}

export default React.memo(FloatingSidebar)
