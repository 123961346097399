import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import Map, { GeolocateControl } from 'react-map-gl'
import FloatingSidebar from 'components/sidebar/FloatingSidebar'
import MapboxLanguage from '@mapbox/mapbox-gl-language'
import { Search } from 'components/search'
import * as React from 'react'
import { ReactComponent as HomezoneCenter } from 'assets/icons/homezone_center.svg'
import { ReactComponent as HomezoneRadius } from 'assets/icons/homezone_radius.svg'
import { ReactComponent as BoundaryPoint1 } from 'assets/icons/randpunkt1.svg'
import { ReactComponent as BoundaryPoint2 } from 'assets/icons/randpunkt2.svg'
import { Box, Divider, InputAdornment, Typography, Icon, Slider, CircularProgress } from '@mui/material'
import MapOverlay from 'components/map/MapOverlay'
import useRadiusSlider from 'components/map/useRadiusSlider'
import turfDistance from '@turf/distance'
import { round } from 'lodash'
import turfCircle from '@turf/circle'
import turfBbox from '@turf/bbox'
import geoViewport from '@mapbox/geo-viewport'
import PoiLayer from './map/pois/PoiLayer'
import { convertBoundsToRequestParams } from 'util/mapboxGlHelper'
import useGrid from 'lsd/useGrid'
import { PLATFORM_REGIO, PROVIDER_MAPBOX, PROVIDER_TRIAS } from 'util/constants'
import useHomeZoneQueries from 'components/homeZone/useHomeZoneQueries'
import MapHomeZoneDetailList from './sidebar/MapHomeZoneDetailList'
import { useDebounce } from 'react-use'
import { HOMEZONE_COLOR } from 'util/styleConstants'

const MIN_RADIUS = 0.2 // km
const MAX_RADIUS = 40
const INITIAL_RADIUS = 2

const INITIAL_MAP_ZOOM = 13.49248422228229

const INITIAL_HZ_LNGLAT = [8.404415499999999, 49.013616]

const searchSx = {
  height: '40px',
  borderRadius: '24px',
  backgroundColor: '#FAFAFA',
  boxShadow: '0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.2)',
  border: 'solid #FAFAFA',
}

/* Main Component including the Map, overlays and the logic of the map behaviour
*  Also includes various calculations and constants needed for map/homezone coordination
*/
const MapHomeZoneScreen = () => {
  const [viewport, setViewport] = useState(null)
  const [newRegion, setNewRegion] = useState(null)
  const mapRef = useRef()
  const svgRef = useRef()

  // Radius and radius center of Homezone circle
  const [radius, setRadius] = useState(INITIAL_RADIUS)
  const [radiusCenter, setRadiusCenter] = useState(INITIAL_HZ_LNGLAT)

  // Boundary Points of homezone circle
  const [boundaryPoint1, setBoundaryPoint1] = useState(null)
  const [boundaryPoint2, setBoundaryPoint2] = useState(null)

  const [dragging, setDragging] = useState(false)

  // Needed to trigger the initializeMap function
  const [mapReady, setMapReady] = useState(false)

  const [userLocation, setUserLocation] = useState(null)

  // Calculated map bounds
  const [bounds, setBounds] = useState({})

  // Tabs for different modes
  const [activeTopTab, setActiveTopTab] = useState(0)

  // Overlay modes
  const overlayMode = useMemo(() => {
    if (activeTopTab === 0) {
      return 'centerAndRadius'
    } else if (activeTopTab === 1) {
      return 'boundaryPoints'
    } else {
      return null
    }
  }, [activeTopTab])

  // Debounced bounds
  useDebounce(() => {
    const bounds = geoViewport.bounds([viewport?.longitude, viewport?.latitude], viewport?.zoom, [window.innerWidth, window.innerHeight])
    setBounds(convertBoundsToRequestParams([[bounds[2], bounds[3]], [bounds[0], bounds[1]]]))
  }, 200, [viewport])

  // Padding of the Camera to fit the Map to the Homezone Circle
  const cameraPadding = useCallback(() => {
    if (svgRef?.current && mapReady) {
      const { left, top, width, height } = svgRef?.current?.getBoundingClientRect()
      return {
        top: top,
        left: left,
        bottom: (window.innerHeight - top - height),
        right: (window.innerWidth - left - width),
      }
    }
  }, [mapReady])

  // Setting a new Viewport and sets the Region immediately too
  const setNewViewport = useCallback(newViewport => {
    mapRef?.current?.flyTo(
      {
        center: [newViewport.longitude, newViewport.latitude],
        zoom: newViewport.zoom,
        padding: cameraPadding(),
        duration: 1000,
      })
  }, [cameraPadding])

  // get home zone data from backend
  // need to convert radius from kilometers to meters
  const { definition, stops } = useHomeZoneQueries(radiusCenter?.[1], radiusCenter?.[0], radius * 1000)

  /**
     * radius calculated from center and coordinates of svg overlay
     * @param newViewport
     * @returns {{radiusCenterInMap: unknown, radius: number}|{radiusCenterInMap: *, radius: number}}
     */
  const calculateRadius = useCallback(() => {
    if (svgRef?.current && mapRef?.current && mapReady) {
      const { left, top, width, height } = svgRef?.current?.getBoundingClientRect()
      const radiusCenterInView = [
        left + width / 2,
        top + height / 2,
      ]
      const radiusCenterInMap = mapRef.current.unproject(radiusCenterInView)
      // construct boundary point in view: x = left svg, y = y of center
      const radiusBoundaryPointInView = [left, radiusCenterInView[1]]
      // convert back to map coordinates
      const radiusBoundaryPointInMap = mapRef.current.unproject(radiusBoundaryPointInView)
      // calculate distance
      const distance = turfDistance([radiusCenterInMap.lng, radiusCenterInMap.lat], [radiusBoundaryPointInMap.lng, radiusBoundaryPointInMap.lat])

      return {
        radius: round(distance, 3),
        radiusCenterInMap: [radiusCenterInMap.lng, radiusCenterInMap.lat],
      }
    }
  }, [mapReady])

  /**
     * radius calculated from center and view coordinates of svg overlay
     */
  useEffect(() => {
    if (svgRef?.current && mapRef?.current && newRegion) {
      const { radius, radiusCenterInMap } = calculateRadius()
      // radius limited to 40km, because no result is provided by init for higher radius
      setRadius(Math.min(MAX_RADIUS, radius))
      setRadiusCenter(radiusCenterInMap)
    }
  }, [newRegion, calculateRadius])

  // checks for URL parameters and sets homezone to given parameters
  useEffect(() => {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    if (urlParams.has('radius') && urlParams.has('lng') && urlParams.has('lat')) {
      const paramRadius = Number(urlParams.get('radius'))
      const paramRadiusCenter = [Number(urlParams.get('lng')), Number(urlParams.get('lat'))]
      setRadius(Math.min(MAX_RADIUS, paramRadius))
      setRadiusCenter(paramRadiusCenter)
    }
  }, [])

  // Request Pois
  const pois = useGrid(bounds.minLat, bounds.minLng, bounds.maxLat, bounds.maxLng, PLATFORM_REGIO, PROVIDER_TRIAS)

  // Extract gids from homezone stops
  const gidsFromStops = useMemo(() => stops?.value?.map(stop => stop.properties.id), [stops])

  // poi/stop list cleared from non existing stops
  const poisForMapAndList = useMemo(() => {
    return pois?.features
      .filter((poi) => {
        return gidsFromStops?.includes(poi.properties?.bookingId)
      })
  }, [gidsFromStops, pois])

  /**
     *
     * @type {(function(*): (*|undefined))|*}
     */
  const getZoomLevelFromRadius = useCallback((radius) => {
    // calculate bbox, needed since zoomLevel depends on it in mapbox
    if (mapReady) {
      const { left, right, top, width, height } = svgRef?.current?.getBoundingClientRect()
      // Calculates ratio of radius to height or width, whichever is greater.
      const radiusCalc = window.innerHeight < window.innerWidth
        ? (radius / (height / 2)) * top
        // need innerWith because of marginLeft of 40%
        : (radius / (width / 2)) * ((left + window.innerWidth - right) / 2)

      const circle = turfCircle(
        radiusCenter,
        radius + radiusCalc
      )

      const bbox = turfBbox(circle)
      // calculate zoom using geoviewport
      const { zoom } = geoViewport.viewport(
        bbox,
        [window.innerWidth, window.innerHeight],
        0, 22, 512, true // minZoom, maxZoom, tileSize, useFloat
      )
      return zoom
    }
  }, [mapReady, radiusCenter])

  // Min and Max zoom levels calculated with min and max radius
  const minZoomLevel = useMemo(() => getZoomLevelFromRadius(MAX_RADIUS), [getZoomLevelFromRadius])
  const maxZoomLevel = useMemo(() => getZoomLevelFromRadius(MIN_RADIUS), [getZoomLevelFromRadius])

  /**
     * Initialize camera.
     */
  const initializeMap = useCallback(() => {
    if (!mapRef.current || !svgRef?.current) {
      return
    }
    setNewViewport({
      zoom: getZoomLevelFromRadius(radius),
      longitude: radiusCenter[0],
      latitude: radiusCenter[1],
    })
    setIsCameraInitialized(true)
  }, [getZoomLevelFromRadius, radius, radiusCenter, setNewViewport])

  // reset boundary points when switching back to other
  useEffect(() => {
    if (overlayMode !== 'boundaryPoints') {
      setBoundaryPoint1(null)
      setBoundaryPoint2(null)
    }
  }, [overlayMode])

  const [isCameraInitialized, setIsCameraInitialized] = useState(false)

  // Initialize homezone overlay dimensions and Map with initial values
  useEffect(() => {
    if (!isCameraInitialized && mapRef?.current && svgRef?.current && mapReady) {
      initializeMap()
    }
  }, [isCameraInitialized, initializeMap, mapReady])

  /**
   * Sets the new Region after a short time to ensure that calculations are not thrown too often while handling the Map
   */
  useDebounce(() => {
    setNewRegion(viewport)
  }, 400, [viewport])

  // Sync radius value to slider value
  const {
    getSliderProps,
  } = useRadiusSlider(
    MIN_RADIUS,
    MAX_RADIUS,
    calculateRadius,
    radius,
    radiusCenter,
    mapRef
  )

  /**
     * Search Input component prepared for all cases
     * @type {(...args: any[]) => any}
     */
  const SearchComponent = useCallback((onSelectFunc, startAdornment, placeholder, autoFocus = true) => (
        <Search
            onSelect={onSelectFunc}
            textFieldProps={{
              sx: {
                ...searchSx,
                width: (theme) => `calc(100% - ${theme.spacing()})`,
              },
              InputProps: {
                startAdornment: <InputAdornment position='start'>{startAdornment}</InputAdornment>,
              },
              placeholder: placeholder,
            }}
            providers={[PROVIDER_TRIAS, PROVIDER_MAPBOX]}
            autoFocus={autoFocus}
            clearInput={overlayMode === 'centerAndRadius' ? dragging : false}
            coordinates={userLocation ? [userLocation.longitude, userLocation.latitude] : radiusCenter}
        />
  ), [dragging, overlayMode, userLocation, radiusCenter])

  /**
     * Panel Content for Points and Radius mode
     * @type {unknown}
     */
  const renderPanelContent = useCallback((topContent) => (
        <Box display='grid' alignContent='flex-start' width='100%'>
            {topContent}
            <Divider flexItem sx={{
              margin: (theme) => theme.spacing(2, -3),
              width: (theme) => `calc(100% + ${theme.spacing(5.5)})`,
            }}
            />
            <Typography variant='overline' align='left' sx={{
              letterSpacing: '0.125rem',
              fontWeight: 550,
              lineHeight: '1.5',
            }}
            >
                Haltestellen der Homezone
            </Typography>
            {
                (stops?.loading || !stops?.value)
                  ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', pt: theme => theme.spacing(3) }}>
                        <CircularProgress />
                    </Box>
                    )
                  : <MapHomeZoneDetailList items={poisForMapAndList} />
            }

        </Box>
  ), [poisForMapAndList, stops?.loading, stops?.value])

  const handleMapRef = useCallback(map => {
    if (map) {
      map.addControl(new MapboxLanguage({
        defaultLanguage: 'de',
      }))
      mapRef.current = map
    }
  }, [])

  return (
        <Box height='100vh' width='100vw'>
            <Map
                {...viewport}
                ref={handleMapRef}
                mapStyle='mapbox://styles/raumobil/ckteejisk28it17p70aneo0lr'
                mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
                initialViewState={{
                  longitude: INITIAL_HZ_LNGLAT[0],
                  latitude: INITIAL_HZ_LNGLAT[1],
                  zoom: INITIAL_MAP_ZOOM,
                  padding: cameraPadding(),
                }}
                interactive={overlayMode !== 'boundaryPoints'}
                dragRotate={false}
                touchZoomRotate={false}
                minZoom={minZoomLevel}
                maxZoom={maxZoomLevel}
                onResize={e => {
                  mapRef.current?.flyTo(
                    {
                      center: [e.target.transform._center.lng, e.target.transform._center.lat],
                      zoom: getZoomLevelFromRadius(radius),
                      padding: cameraPadding(),
                      duration: 1000,
                    })
                }}
                style={{
                  width: '100%',
                  height: '100%',
                  pointerEvents: overlayMode === 'boundaryPoints' ? 'none' : 'initial',
                }}
                onMove={(e) => {
                  setViewport(e?.viewState)
                }}
                onZoom={e => {
                  // Do not set Viewport if flyTo is active
                  if (!e.target._moving) {
                    setViewport({
                      ...e?.viewState,
                      longitude: radiusCenter[0],
                      latitude: radiusCenter[1],
                      padding: cameraPadding(),
                    })
                  }
                }}
                onDragStart={() => setDragging(true)}
                onDragEnd={() => setDragging(false)}
                onIdle={(e) => {
                  setNewRegion({
                    longitude: e?.target.transform._center.lng,
                    latitude: e?.target.transform._center.lat,
                    zoom: getZoomLevelFromRadius(radius),
                  })
                }}
                onLoad={() => {
                  setMapReady(true)
                }}
            >
                <GeolocateControl
                    position='bottom-right'
                    style={{
                      borderRadius: '50%',
                      margin: 6,
                      position: 'absolute',
                      bottom: 'calc(5vh - 6px)',
                      right: '24px',
                    }}
                    onGeolocate={e => {
                      setUserLocation(e?.coords)
                    }}
                    onError={() => setUserLocation(null)}
                />
                <PoiLayer
                    pois={pois}
                    poisForMapAndList={poisForMapAndList}
                    filterCenterPoint={radiusCenter}
                    filterRadius={radius}
                />
                <MapOverlay
                    ref={svgRef}
                    setViewport={setNewViewport}
                    boundaryPoint1={boundaryPoint1}
                    boundaryPoint2={boundaryPoint2}
                    overlayMode={overlayMode}
                    radius={radius}
                    definition={definition}
                    map={mapRef}
                />
            </Map>
            <FloatingSidebar
                changeFunc={setActiveTopTab}
                RadiusPC={renderPanelContent(
                  <>
                  {SearchComponent(
                    (item) => { mapRef.current?.flyTo({ center: item?.geometry?.coordinates, duration: 1000 }) },
                        <HomezoneCenter />,
                        'Zentrum suchen'
                  )}
                    <Box sx={searchSx} width='calc(100% - 12px)' mt={1} mb={1}>
                        <Box p='6px' display='flex'>
                            <Icon sx={{
                              width: '25px',
                              height: '25px',
                              alignSelf: 'center',
                            }}
                            >
                            <HomezoneRadius />
                            </Icon>
                            <Slider
                                {...getSliderProps}
                                sx={{
                                  marginLeft: (theme) => theme.spacing(2),
                                  marginRight: (theme) => theme.spacing(2),
                                  color: HOMEZONE_COLOR,
                                }}
                            />
                        </Box>
                    </Box>
                  </>
                )}
                PointsPC={renderPanelContent(
                  <>
                  {SearchComponent(
                    (item) => { setBoundaryPoint1(item?.geometry?.coordinates) },
                                <BoundaryPoint1 />,
                                'Randpunkt 1 eingeben'
                  )}
                  {SearchComponent(
                    (item) => { setBoundaryPoint2(item?.geometry?.coordinates) },
                                <BoundaryPoint2 />,
                                'Randpunkt 2 eingeben',
                                false
                  )}
                  </>
                )}
            />
        </Box>
  )
}

export default MapHomeZoneScreen
