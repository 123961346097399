import React from 'react'
import { Box, Link } from '@mui/material'
import AppleAppStore from 'assets/images/appStore_apple.jpg'
import GoogleAppStore from 'assets/images/appStore_google.png'

const AppStoreLinks = () => {
  return (
    <Box sx={{ marginTop: '20px' }}>
      <Link href="https://apps.apple.com/de/app/kvv-regiomove/id1465606361" rel="noopener noreferrer" target={'_blank'} underline="none">
        <img src={AppleAppStore} alt={'Apple Appstore Link'} />
      </Link>
      <Link href="https://play.google.com/store/apps/details?id=com.raumobil.smartmobilitymap.regiomove" rel="noopener noreferrer" target={'_blank'} underline="none">
        <img src={GoogleAppStore} alt={'Google Appstore Link'} />
      </Link>
    </Box>
  )
}

export default AppStoreLinks
