import React from 'react'
import PropTypes from 'prop-types'
import { Layer, Source } from 'react-map-gl'
import {
  TRIAS_POIS_INSIDE_COLOR,
  TRIAS_POIS_INSIDE_STROKE_COLOR,
  TRIAS_POIS_OUTSIDE_COLOR,
  TRIAS_POIS_OUTSIDE_STROKE_COLOR,
} from 'util/styleConstants'
import turfCircle from '@turf/circle'

/**
 * Single mapbox layer with pois
 *
 * @component
 */
const PoiLayer = ({
  pois,
  poisForMapAndList,
  filterCenterPoint,
  filterRadius,
}) => {
  const poisForMapAndListGeojson = {
    type: 'FeatureCollection',
    features: poisForMapAndList,
  }

  return pois && (
      <>
        {poisForMapAndList?.length > 0 && (
            <Source
                id='pois_source_in'
                type='geojson'
                data={poisForMapAndListGeojson}
            >
              {/* Circles  */}
              <Layer
                  id='pois_in'
                  source='trias_pois_inside'
                  type='circle'
                  paint={{
                    'circle-color': TRIAS_POIS_INSIDE_COLOR,
                    'circle-stroke-color': TRIAS_POIS_INSIDE_STROKE_COLOR,
                    'circle-stroke-width': 1,
                    'circle-radius': 5,
                  }}
              />
            </Source>
        )}
        <Source
          id='pois_source_out'
          type='geojson'
          data={pois}
        >
          {/* Circles  */}
          <Layer
            id='pois_out'
            source='trias_pois_outside'
            type='circle'
            paint={{
              'circle-color': TRIAS_POIS_OUTSIDE_COLOR,
              'circle-stroke-color': TRIAS_POIS_OUTSIDE_STROKE_COLOR,
              'circle-stroke-width': 1,
              'circle-radius': 5,
            }}
            filter={['==', ['within', turfCircle(
              filterCenterPoint,
              filterRadius
            )], false]}
          />
        </Source>
      </>
  )
}

PoiLayer.propTypes = {
  /** Pois for the map outside the homezone */
  pois: PropTypes.object,
  /** Pois for inside the homezone */
  poisForMapAndList: PropTypes.array,
  /** Center point of the homezone, used to filter pois */
  filterCenterPoint: PropTypes.array,
  /** Radius of the homezone, used to filter pois */
  filterRadius: PropTypes.number,
}

PoiLayer.defaultProps = {
  minZoom: 11,
  maxZoom: 24, // Appearantly mapbox has zoom of 26 in native
  iconZoomStart: 14,
  markerZoomStart: 17,
  color: 'lightgray',
  borderColor: 'darkgray',
  iconColor: 'darkgray',
}

export default PoiLayer
